import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import {
  fetchOrgs,
  fetchUsuarioSenescyt,
  handleCreatePerfilInvestigador,
  handleFetchInstanciasTarea,
  hadleFetchBosquesProtectores,
  handleFetchLaboratoriosAcreditados,
  handleFetchSolicitudesAprobadas,
  handleFetchSolicitudesNoAprobadas,
  clearAprobadas,
  clearNoAprobadas,
} from 'src/features/App/sliceApp'
import { Stack, AppBar } from '@mui/material'
import { LoginForm2 } from 'src/features/App/subcomponents/LoginForm2'
import { LoginForm3 } from 'src/features/App/subcomponents/LoginForm3'
import { appBarStyles } from 'src/styles/styles'
import { AppSnackbar } from 'src/features/App/subcomponents/AppSnackBar'
import { f } from 'src/commons/f'
import { MenuUsuario } from './subcomponents/MenuUsuario'
import { ENV } from 'src/features/App/globals'
import { Chat } from "src/features/chat";
import { retornaChatRooms } from '../chat/API'
import {MenuInvestigador} from "./subcomponents/MenuInvestigador"

export const Widget = () => {
  const dispatch = useDispatch()
  const showLoginForm = useSelector(state => state.app.showLoginForm)
  const usuario = useSelector(state => state.app.usuario)
  const investigador = useSelector(state => state.app.investigador)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const crearPerfilInvestigador = useSelector(state => state.app.crearPerfilInvestigador)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  // const funcionarios = useSelector(state => state.app.funcionarios)
  // const instanciaProceso = useSelector(state => state.app.instanciaProceso)

  useEffect(() => {
    dispatch(fetchOrgs())
    dispatch(hadleFetchBosquesProtectores())
    dispatch(handleFetchLaboratoriosAcreditados())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (investigador) {
      dispatch(handleFetchSolicitudesAprobadas(perfilUsuario.id))
      dispatch(handleFetchSolicitudesNoAprobadas(perfilUsuario.id))
    } else {
      dispatch(clearAprobadas())
      dispatch(clearNoAprobadas())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perfilUsuario])

  useEffect(() => {
    dispatch(fetchUsuarioSenescyt(usuario))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, usuario])

  useEffect(() => {
    if (crearPerfilInvestigador && !!usuario.id) {
      dispatch(handleCreatePerfilInvestigador(usuario.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, usuario.id, crearPerfilInvestigador])

  useEffect(() => {
    if (!!perfilUsuario?.id) {
      dispatch(handleFetchInstanciasTarea(perfilUsuario))
    }
  }, [dispatch, perfilUsuario])

  useEffect(() => {
    if(perfilUsuario?.id){
      dispatch(retornaChatRooms(perfilUsuario.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perfilUsuario])

  const user = usuario.nombreUsuario ? usuario.nombreUsuario : ''

  const { chatRooms } = useSelector(state => state.chat)

  return (
    <Box sx={{ height: '100%', mt: '64px' }}>
      <AppBar position="fixed" sx={{ ...appBarStyles.appBar, padding: 0 }} elevation={0}>
        <Toolbar style={{ ...appBarStyles.toolBar, width:'100%',padding: 0}}>
          <Box sx={{ width: '100%', height: '100%', margin: '0 1rem 0 1rem'}}>
            <Stack direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   spacing={2} >
              <Box>
                {f.isValid(user) && investigador && <><MenuInvestigador/></>}
              </Box>
              <Box>
                <Stack direction={'row'} spacing={2}>
                    {
                      (chatRooms.length !== 0 || parseInt(instanciaTarea?.perfilUsuarioId || 0) === perfilUsuario.id ) &&
                      <Stack direction={'row'} spacing={0}>
                        <Chat />
                      </Stack>
                    }
                    { f.isValid(user) && <><MenuUsuario /></> }
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      {showLoginForm ? (ENV === 'PRUEBAS') ? <LoginForm2 /> : <LoginForm3 /> : null}
      <AppSnackbar />
    </Box>
  )
}
