import React from 'react'
import {dialog} from 'src/styles/styles'
import {InputAdornment, TextField} from '@mui/material'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {f} from "../commons";

export const MyTextField = ({
                              id,
                              label,
                              value,
                              formValues,
                              icon,
                              handleChange,
                              error='',
                              isNumber=false,
                              placeholder='',
                              canEdit=true}) => {
  const normalSx = {...dialog.textTypography, m:'1rem 0 1rem 0'}
  const sx = normalSx
  const type = isNumber ? 'number' : 'text'
  const compulsive = (type === 'text') && canEdit && (label?.length > 0) && (label?.at(label.length-1) === '*')
  const borderStyle = (compulsive && (formValues[id] === ''))? {border: '2px solid red'} : {border: '1px dotted #cccccc'}
  const error2 = (compulsive && (formValues[id] === '')) ? `Campo "${label}" es obligatorio.`:""
  const error1 = ((f.isValid(error) && error.length>0)?error:"") + ((error2.length>0)?(" - " + error2):"")
  return (
    <TextField id={id}
               label={label}
               value={!!value?value:f.isValid(formValues[id])?formValues[id]:''}
               type={type}
               fullWidth
               variant='standard'
               aria-readonly={true}
               sx={sx}
               onChange={canEdit?handleChange:false}
               placeholder={placeholder}
               InputProps={{
                 inputProps:{style:{textAlign: isNumber?'right':'left',}},
                 disableUnderline: true,
                 form: {autocomplete: 'off'},
                 startAdornment:(
                   <InputAdornment position="start">
                     {icon}
                   </InputAdornment>
                 ),
                 sx: {
                   fontSize: '0.9rem',
                   fontfamily: RobotoCondensedRegular,
                   color: '#888888',
                   backgroundColor: (canEdit===true)?'white':'transparent',
                   ...borderStyle,
                 }
               }}
               InputLabelProps={{ sx: {
                   fontSize: '1.2rem',
                   color: '#888888',
                   fontfamily: RobotoCondensedRegular,
                   padding: '-24px',
                   shrink: true
                 }}}
               FormHelperTextProps={{sx: {
                     fontSize: '0.7rem',
                     fontfamily: RobotoCondensedRegular,
                     lineHeight: '1.2rem',
                     color:'black',
                     fontWeight: 'normal',
                     pl:'1rem'}}}
               helperText={error1} />
  )
}
