import {
  AlignmentType,
  BorderStyle,
  convertInchesToTwip,
  Document,
  Footer,
  Header,
  HeightRule, Indent, NumberFormat, Numbering,
  PageNumber,
  PageOrientation,
  Paragraph, ShadingType,
  Table,
  TableCell,
  TableRow, TableRowHeight,
  WidthType
} from "docx";
import {
  createLogoFooterSenescyt,
  createLogoHeader,
  dxBulletParagraph,
  dxC1C2,
  dxC1C2line,
  dxParagraph,
  dxTextRun,
  dxTitle,
  dxQr,
} from "src/components/MyDocx"
import {f} from "../commons";
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {MyTableCell, MyTableCell2} from "./MyTableCell";

export const MyTagged2Docx = (taggeDoc, nombreOrganizacion, mapping, orientation=PageOrientation.PORTRAIT) =>  {
  let doc = mix(taggeDoc, mapping)
  const parrafos = doc?.split('${SALTO}')
  const MyHeader = createLogoHeader(nombreOrganizacion.toLowerCase())
  const sections = [
    {
      properties: {
        page: {
          pageNumbers: {
            start: 1,
            formatType: NumberFormat.DECIMAL,
          },
          margin: {
            top: (nombreOrganizacion==='senescyt') ? 2200: 2200,
            right: 1000,
            bottom: 2000,
            left: 1200,
          },
          size: {
            orientation,
          },
        },
      },
      headers: {
        default: new Header({
          children: [MyHeader],
        }),
      },
      footers: {
        default: new Footer({
          children: [createLogoFooterSenescyt(nombreOrganizacion.toLowerCase()),],
        }),
      },
      children: []
    }
  ]

  parrafos?.forEach(it => {
    const res = checkDocxTag(it)
    if(!f.isValid(res))
      console.log('ERROR, no tag: ')
    else {
      if(res.index === 0) {
        let parrafo = it?.substring(res[0].length)
        let salto2 = parrafo.includes('${SALTO2}')
        let salto3 = parrafo.includes('${SALTO3}')
        if(salto2)
          parrafo = parrafo.replace('${SALTO2}','')
        if(salto3)
          parrafo = parrafo.replace('${SALTO3}','')
        const fn = {
          '${QR}': (p) => {
            const uri = encodeURI(`"${p}"`)
            const qr = fetch(
              `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${uri}&format=png`
            ).then((r) => r.blob())
            sections[0].children.push(dxQr(qr))
          },
          '${TITULO1}':   (p) => sections[0].children.push(dxTitle({text:p})),
          '${TITULO2}':   (p) => sections[0].children.push(dxTitle({text:p, level:'HEADING_2', alignment:'LEFT'})),
          // '${TÍTULO3}':   (p) => sections[0].children.push(dxTitle({text:p, level:'HEADING_3', alignment:'LEFT'})),
          '${TITULO3}':   (p) => sections[0].children.push(dxTitle({text:p, level:'HEADING_3', alignment:'LEFT'})),
          '${VAR}':       (p) => {
            const arr = p.split('${:}')
            if(arr.length >= 2) {
              sections[0].children.push(dxC1C2({text1:arr[0], text2:arr[1],}))
            } else {
              sections[0].children.push(dxC1C2({text1:arr[0], text2:'- - - - - - ',}))
            }
          },
          '${VAR2}':       (p) => {
            const arr = p.split('${:}')
            if(arr.length >= 2) {
              // sections[0].children.push(dxC1C2line({text1:arr[0], text2:arr[1],c1width:48}))
              const rows=[]
              rows.push(
                new TableRow({
                  children: [
                    MyTableCell({text: arr[0], size: 40, alignment: AlignmentType.START, bold:false}),
                    MyTableCell({text: arr[1], size: 50, alignment: AlignmentType.JUSTIFIED, bold:false}),
                  ],
                })
              )
              const table = new Table({
                rows: rows,
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
              sections[0].children.push(table)
            }
          },
          '${PARRAFO}':   (p) => {
            sections[0].children.push(dxParagraph([
              dxTextRun({text:p})
            ]))
          },
          '${PARRAFO.VACIO}': () => {
            sections[0].children.push(dxParagraph([
              dxTextRun({text:'', })
            ], 0, 0, 280))
          },
          '${PUNTO.PARRAFO}':
            (p) => {
              sections[0].children.push(dxBulletParagraph([
                dxTextRun({text:p})
              ]))
            },
          '${CENTRAR}': (p) => {
            sections[0].children.push(dxTitle({text:p, level: 'HEADING_4', after:30}))
          },
          '${CENTRARBOLD}':
            (p) => {
              sections[0].children.push(dxTitle({text:p, level: 'HEADING_3', after:30}))
            },
          '${WF07.RESPONSABLES}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `Identificador`, size: 15, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombres y apellidos', size: 40, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nacionalidad', size: 30, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: '¿transporta?', size: 15, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: it.cedula, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.nombre, size: 24, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.pais, size: 8, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.transporta?'SI':'NO', size: 8, alignment: AlignmentType.CENTER, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${WF07.RECURSOS}': (p) => {
            JSON.parse(p).forEach(it => {
              sections[0].children.push(dxParagraph([dxTextRun({text:it.scientificname + '. ' + it.tipo + ', cantidad: ' + it.cantidadSolicitada})]))
            })
          },
          '${RECURSOSATM}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `#`, size: 4, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 24, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Origen', size: 8, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: '¿Holotipo?', size: 8, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Tipo', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Método', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Tratamiento', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  // MyTableCell({text: 'Cantidad autorizada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad solicitada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: `${idx+1}`, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.scientificname, size: 24, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.origen, size: 8, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.holotipo?'SI':'NO', size: 8, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.tipo, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.metodo, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.tratamiento, size: 12, alignment: AlignmentType.START, bold:false}),
                  // MyTableCell({text: it.cantidadAutorizada, size: 10, alignment: AlignmentType.END, bold:false}),
                  MyTableCell({text: `${it.cantidadSolicitada}`, size: 10, alignment: AlignmentType.END, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${RECURSOSATMIS}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `#`, size: 4, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 24, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Tipo recolección', size: 8, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Submuestra', size: 8, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Otra submuestra', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Descripción', size: 14, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad autorizada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad declarada', size: 10, alignment: AlignmentType.CENTER, bold:true}),

                  // MyTableCell({text: 'Cantidad solicitada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: `${idx+1}`, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.scientificname, size: 24, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.tipo, size: 8, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.submuestra, size: 8, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.otraSubmuestra, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.descripcion, size: 14, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: `${it.cantidadAutorizada}`, size: 10, alignment: AlignmentType.RIGHT, bold:false}),
                  MyTableCell({text: `${it.cantidadSolicitada}`, size: 10, alignment: AlignmentType.RIGHT, bold:false}),
                  // MyTableCell({text: it.cantidadAutorizada, size: 10, alignment: AlignmentType.END, bold:false}),
                  // MyTableCell({text: it.cantidadSolicitada, size: 10, alignment: AlignmentType.END, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${RECURSOSATMES}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `#`, size: 4, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'rango', size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: 'Centro documentación', size: 24, alignment: AlignmentType.START, bold:true}),
                  // MyTableCell({text: 'Otra submuestra', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  // MyTableCell({text: 'Descripción', size: 14, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad autorizada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad declarada', size: 10, alignment: AlignmentType.CENTER, bold:true}),

                  // MyTableCell({text: 'Cantidad solicitada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: `${idx+1}`, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.taxonrank, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.scientificname, size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.centroDocumentacion, size: 24, alignment: AlignmentType.START, bold:false}),
                  // MyTableCell({text: it.otraSubmuestra, size: 12, alignment: AlignmentType.START, bold:false}),
                  // MyTableCell({text: it.descripcion, size: 14, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: `${it.cantidadAutorizada}`, size: 10, alignment: AlignmentType.RIGHT, bold:false}),
                  MyTableCell({text: `${it.cantidadSolicitada}`, size: 10, alignment: AlignmentType.RIGHT, bold:false}),
                  // MyTableCell({text: it.cantidadAutorizada, size: 10, alignment: AlignmentType.END, bold:false}),
                  // MyTableCell({text: it.cantidadSolicitada, size: 10, alignment: AlignmentType.END, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${RECURSOSATMES2}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `#`, size: 4, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'rango', size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 24, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: 'Método', size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: 'Tratamiento', size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: 'Cantidad autorizada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad declarada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: `${idx+1}`, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.taxonrank, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.scientificname, size: 24, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.metodo, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.tratamiento, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: `${it.cantidadSolicitada}`, size: 10, alignment: AlignmentType.RIGHT, bold:false}),
                  MyTableCell({text: `${it.holotiposSolicitados}`, size: 10, alignment: AlignmentType.RIGHT, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${RECURSOSDRM}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `#`, size: 4, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 24, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: '¿Holotipo?', size: 8, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Tipo', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Método', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Tratamiento', size: 12, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad autorizada', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Cantidad declarado', size: 10, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: `${idx+1}`, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.scientificname, size: 24, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.holotipo?'SI':'NO', size: 8, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.tipo, size: 12, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.metodo, size: 14, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.tratamiento, size: 14, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: `${it.cantidadAutorizada}`, size: 12, alignment: AlignmentType.END, bold:false}),
                  MyTableCell({text: `${it.cantidadSolicitada}`, size: 12, alignment: AlignmentType.END, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${RECURSOSDRM_IS}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `#`, size: 4, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Rango', size: 12, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 24, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Submuestra', size: 18, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Otra submuestra', size: 18, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Descripción', size: 24, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Cantidad autorizada', size: 12, alignment: AlignmentType.RIGHT, bold:true}),
                  MyTableCell({text: 'Cantidad declarada', size: 12, alignment: AlignmentType.RIGHT, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: `${idx+1}`, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.taxonrank, size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.scientificname, size: 24, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.submuestra, size: 18, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.otraSubmuestra, size: 18, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: it.descripcion, size: 24, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: `${it.cantidadAutorizada}`, size: 12, alignment: AlignmentType.END, bold:false}),
                  MyTableCell({text: `${it.cantidadSolicitada}`, size: 12, alignment: AlignmentType.END, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${RECURSOSDRM_ES}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `#`, size: 4, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Rango', size: 12, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 24, alignment: AlignmentType.LEFT, bold:true}),
                  // MyTableCell({text: 'Submuestra', size: 18, alignment: AlignmentType.LEFT, bold:true}),
                  // MyTableCell({text: 'Otra submuestra', size: 18, alignment: AlignmentType.LEFT, bold:true}),
                  // MyTableCell({text: 'Descripción', size: 24, alignment: AlignmentType.LEFT, bold:true}),
                  MyTableCell({text: 'Cantidad autorizada', size: 12, alignment: AlignmentType.RIGHT, bold:true}),
                  MyTableCell({text: 'Cantidad declarada', size: 12, alignment: AlignmentType.RIGHT, bold:true}),
                ],
              })
            )
            items.forEach((it, idx) => {
              const trow = new TableRow({
                children: [
                  MyTableCell({text: `${idx+1}`, size: 4, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: it.taxonrank, size: 12, alignment: AlignmentType.START, bold:true}),
                  MyTableCell({text: it.scientificname, size: 24, alignment: AlignmentType.CENTER, bold:false}),
                  // MyTableCell({text: it.submuestra, size: 18, alignment: AlignmentType.START, bold:false}),
                  // MyTableCell({text: it.otraSubmuestra, size: 18, alignment: AlignmentType.START, bold:false}),
                  // MyTableCell({text: it.descripcion, size: 24, alignment: AlignmentType.START, bold:false}),
                  MyTableCell({text: `${it.cantidadAutorizada}`, size: 12, alignment: AlignmentType.END, bold:false}),
                  MyTableCell({text: `${it.cantidadSolicitada}`, size: 12, alignment: AlignmentType.END, bold:false}),
                ],
              })
              rows.push(trow)
            })
            const table = new Table({
              rows: rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            })
            sections[0].children.push(table)
          },
          '${FIRMA}': (p) => {
            const [titulo, persona, fecha] = p.split('{:}')
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            sections[0].children.push(new Table({
              rows:[
                new TableRow({
                  children: [
                    MyTableCell({text: titulo, size: 100, alignment: AlignmentType.CENTER, bold:false, colSpan:3, shading}),
                  ],
                }),
                new TableRow({
                  children: [
                    MyTableCell({text: 'Nombre', size: 30, alignment: AlignmentType.CENTER, bold:false, shading}),
                    MyTableCell({text: 'Firma', size: 50, alignment: AlignmentType.CENTER, bold:false, shading}),
                    MyTableCell({text: 'Fecha', size: 20, alignment: AlignmentType.CENTER, bold:false, shading}),
                  ]
                }),
                new TableRow({
                  children: [
                    MyTableCell({text: persona, size: 30, alignment: AlignmentType.CENTER, bold:false}),
                    MyTableCell({text: '', size: 50, alignment: AlignmentType.CENTER, bold:false}),
                    MyTableCell({text: fecha, size: 20, alignment: AlignmentType.CENTER, bold:false}),
                  ],
                  height: { value: 1000, rule: HeightRule.EXACT }
                })
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            }))
          },
          '${FIRMAN}': (p) => {
            const data = JSON.parse(p)
            const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "#ffffff",}
            let rows = [
              new TableRow({
                children: [
                  MyTableCell({text: 'Acción', size: 20, alignment: AlignmentType.CENTER, bold:false, shading}),
                  MyTableCell({text: 'Nombre y apellido', size: 30, alignment: AlignmentType.CENTER, bold:false, shading}),
                  MyTableCell({text: 'Sumilla o firma', size: 30, alignment: AlignmentType.CENTER, bold:false, shading}),
                  MyTableCell({text: 'Fecha', size: 20, alignment: AlignmentType.CENTER, bold:false, shading}),
                ]
              }),
            ]
            rows = [
              ...rows,
              ...data?.map(it => new TableRow({
                children: [
                  MyTableCell({
                    text: it.accion,
                    size: 20,
                    alignment: AlignmentType.CENTER,
                    bold:false
                  }),
                  MyTableCell2({
                    text1: it.nombre,
                    text2: it.cargo,
                    size: 30,
                    alignment: AlignmentType.CENTER,
                    bold:false
                  }),
                  MyTableCell({text: '', size: 30, alignment: AlignmentType.CENTER, bold:false}),
                  MyTableCell({text: today, size: 20, alignment: AlignmentType.CENTER, bold:false}),
                ],
                height: { value: 1500, rule: HeightRule.ATLEAST }
              }))]
            sections[0].children.push(new Table({
              rows:rows,
              // data?.each(it => {
              //   new TableRow({
              //     children: [
              //       MyTableCell({text: it.accion, size: 30, alignment: AlignmentType.CENTER, bold:false}),
              //       MyTableCell({text: it.nombre+'\nññññ', size: 30, alignment: AlignmentType.CENTER, bold:false}),
              //       MyTableCell({text: '', size: 30, alignment: AlignmentType.CENTER, bold:false}),
              //       MyTableCell({text: 'fecha', size: 20, alignment: AlignmentType.CENTER, bold:false}),
              //     ],
              //     height: { value: 1000, rule: HeightRule.EXACT }
              //   })
              // })
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            }))
          },
          '${ENCABEZADO.REPORTE}': (p) => {
            const msge = `Tema:\tAnálisis de la pertinencia, viabilidad y factibilidad del [PROYECTO / PROGRAMA] de investigación titulado/a “${p?.trim()}”`
            sections[0].children.push(new Table({
              rows:[
                new TableRow({
                  children: [
                    MyTableCell({text: 'TEMA', size: 15, alignment: AlignmentType.CENTER, bold:false}),
                    MyTableCell({text: msge, size: 55, alignment: AlignmentType.CENTER, bold:false}),
                    MyTableCell({text: 'PAGINA', size: 15, alignment: AlignmentType.CENTER, bold:false}),
                    MyTableCell({text: `${PageNumber.CURRENT} / ${PageNumber.TOTAL_PAGES_IN_SECTION}`, size: 15, alignment: AlignmentType.CENTER, bold:false}),
                  ]
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            }))
          },
          '${ENCABEZADO.DATOS}': (p) => {
            const [titulo, solicitud] = p?.split('{:}')
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            sections[0].children.push(new Table({
              rows:[
                new TableRow({
                  children: [
                    MyTableCell({text: titulo, size: 100, alignment: AlignmentType.CENTER, bold:true, colSpan:5, shading}),
                  ]
                }),
                new TableRow({
                  children: [
                    MyTableCell({text: 'No. informe', size: 20, alignment: AlignmentType.CENTER, bold:true, shading}),
                    MyTableCell({text: solicitud, size: 80, alignment: AlignmentType.CENTER, bold:true, colSpan:4 }),
                  ]
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }
            }))
          },
          '${ENCABEZADO.DETALLE}': (p) => {
            const [t1, t2, t3, t4, t5, t6, v1, v2, v3, v4, v5] = p?.split('{:}')
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            sections[0].children.push(new Table({
              rows:[
                new TableRow({
                  children: [
                    MyTableCell({text: t1, size: 20, alignment: AlignmentType.CENTER, bold:true, rowSpan:3, shading}),
                    MyTableCell({text: t2, size: 20, alignment: AlignmentType.CENTER, bold:true, rowSpan:2, shading}),
                    MyTableCell({text: t3, size: 40, alignment: AlignmentType.CENTER, bold:true, colSpan:2, shading}),
                    MyTableCell({text: t6, size: 20, alignment: AlignmentType.CENTER, bold:true, rowSpan:2, shading}),
                  ]
                }),
                new TableRow({
                  children: [
                    MyTableCell({text: t4, size: 20, alignment: AlignmentType.CENTER, bold:true, shading}),
                    MyTableCell({text: t5, size: 20, alignment: AlignmentType.CENTER, bold:true, shading}),
                  ]
                }),
                new TableRow({
                  children: [
                    MyTableCell({text: v1, size: 20, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: v4, size: 20, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: v5, size: 20, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: v3, size: 20, alignment: AlignmentType.CENTER, bold:true}),
                  ]
                }),
              ]
            }))
          },
          '${LISTA.SIMPLE}': (p) => {
            const items = p?.split('{:}')
            items.forEach(it => {
              sections[0].children.push(dxParagraph([
                dxTextRun({text:it})
              ]))
            })
          },
          '${LISTA.SIMPLE.NUMERADA}': (p) => {
            const items = p?.split('{:}')?.filter(it => f.isValidNotEmpty(it))
            const noBorder = {style: BorderStyle.NONE, size: 0, color: "#ffffff"}
            items.forEach((it,idx) => {
              sections[0].children.push(
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        MyTableCell({text: `${idx+1}`, size: 5, alignment: AlignmentType.CENTER, noBorder:true}),
                        MyTableCell({text: it, size: 95, alignment: AlignmentType.JUSTIFIED, noBorder:true}),
                      ]
                    })
                  ]
                })
              )
            })
          },
          '${ITEM.SIMPLE.NUMERADO}': (p) => {
            const items = p?.split('{:}')
            sections[0].children.push(
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      MyTableCell({text: `${items[0]}`, size: 5, alignment: AlignmentType.CENTER, noBorder:true}),
                      MyTableCell({text: `${items[1]}`, size: 95, alignment: AlignmentType.JUSTIFIED, noBorder:true}),
                    ]
                  })
                ]
              })
            )
          },
          '${RECURSOS.INSITU}': (p) => {
            const py = JSON.parse(p)
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            py?.recursos?.forEach(it => {
              let rows = []
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: it.scientificname, size: '100%', bold: true, alignment: AlignmentType.START, colSpan:4}),
                ]
              }))
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: 'Muestra/lote', size: '10%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Tipo de muestra', size: '30%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Descripción', size: '50%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Cantidad autorizada', size: '10%', bold: true, alignment: AlignmentType.CENTER, shading}),
                ]
              }))
              py?.muestras?.forEach(m => {
                if(m.taxonid === it.id) {
                  rows.push(new TableRow({
                    children: [
                      MyTableCell({text: m.tipo, size: '10%', alignment: AlignmentType.CENTER}),
                      MyTableCell({text: m.submuestra, size: '30%', alignment: AlignmentType.START}),
                      MyTableCell({text: m.descripcion, size: '50%', alignment: AlignmentType.START}),
                      MyTableCell({text: `${m.cantidadAutorizada}`, size: '10%', alignment: AlignmentType.CENTER}),
                    ]
                  }))
                }
              })
              sections[0].children.push(new Table({rows}))
            })
          },
          '${RECURSOS.INSITU2}': (p) => {
            const py = JSON.parse(p)
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            py?.recursos?.forEach(it => {
              let rows = []
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: it.scientificname, size: '60%', bold: true, alignment: AlignmentType.START, colSpan:3}),
                  MyTableCell({text: it.cites, size: '20%', bold: false, alignment: AlignmentType.START, colSpan:1}),
                  MyTableCell({text: it.redList, size: '20%', bold: false, alignment: AlignmentType.START, colSpan:1}),
                ]
              }))
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: 'Muestra/Lote', size: '10%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Tipo de muestra', size: '25%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Detalle muestra', size: '25%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Cantidad solicitada', size: '20%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Cantidad autorizada', size: '20%', bold: true, alignment: AlignmentType.CENTER, shading}),
                ]
              }))
              py?.muestras?.forEach(m => {
                if(m.taxonid === it.id) {
                  rows.push(new TableRow({
                    children: [
                      MyTableCell({text: m.tipo, size: '10%', alignment: AlignmentType.CENTER}),
                      MyTableCell({text: m.submuestra, size: '25%', alignment: AlignmentType.START}),
                      MyTableCell({text: m.loteotro, size: '25%', alignment: AlignmentType.START}),
                      MyTableCell({text: `${m.cantidadSolicitada}`, size: '20%', alignment: AlignmentType.CENTER}),
                      MyTableCell({text: `${m.cantidadAutorizada}`, size: '20%', alignment: AlignmentType.CENTER}),
                    ]
                  }))
                }
              })
              sections[0].children.push(new Table({rows}))
            })
          },
          '${RECURSOS.EXSITU}': (p) => {
            const py = JSON.parse(p)
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            py?.centrosDocumentacion?.forEach(it => {
              let rows = []
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: it.centroDocumentacion, size: '100%', bold: true, alignment: AlignmentType.START, colSpan:4}),
                ]
              }))
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: 'Grupo', size: '10%', bold: true, alignment: AlignmentType.START, shading}),
                  MyTableCell({text: 'Nombre Cientifico', size: '30%', bold: true, alignment: AlignmentType.START, shading}),
                  MyTableCell({text: 'Tipo Muestra', size: '50%', bold: true, alignment: AlignmentType.START, shading}),
                  MyTableCell({text: 'Cantidad autorizada', size: '10%', bold: true, alignment: AlignmentType.CENTER, shading}),
                ]
              }))
              it.recursos.forEach(r => {
                rows.push(new TableRow({
                  children: [
                    MyTableCell({text: r.artificialGroup, size: '10%', bold: false, alignment: AlignmentType.START}),
                    MyTableCell({text: r.scientificname, size: '30%', bold: false, alignment: AlignmentType.START}),
                    MyTableCell({text: r.tipoMuestra, size: '50%', bold: false, alignment: AlignmentType.START}),
                    MyTableCell({text: `${r.cantidadAutorizada}`, size: '10%', bold: false, alignment: AlignmentType.CENTER}),
                  ]
                }))
              })
              sections[0].children.push(
                new Table({
                  rows,
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  }})
              )
            })
          },
          '${RECURSOS.EXSITU2}': (p) => {
            const py = JSON.parse(p)
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            py?.centrosDocumentacion?.forEach(it => {
              let rows = []
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: it.centroDocumentacion, size: '100%', bold: true, alignment: AlignmentType.START, colSpan:6}),
                ]
              }))
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: 'Nombre Científico', size: '30%', bold: true, alignment: AlignmentType.START, shading}),
                  MyTableCell({text: 'Tipo Muestra', size: '25%', bold: true, alignment: AlignmentType.START, shading}),
                  MyTableCell({text: 'Descripción', size: '25%', bold: true, alignment: AlignmentType.START, shading}),
                  MyTableCell({text: 'Cantidad solicitada', size: '10%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Cantidad autorizada', size: '10%', bold: true, alignment: AlignmentType.CENTER, shading}),
                  MyTableCell({text: 'Espécimen tipo', size: '10%', bold: true, alignment: AlignmentType.CENTER, shading}),
                ]
              }))
              it.recursos.forEach(r => {
                rows.push(new TableRow({
                  children: [
                    MyTableCell({text: r.scientificname, size: '30%', bold: false, alignment: AlignmentType.START}),
                    MyTableCell({text: r.tipoMuestra, size: '25%', bold: false, alignment: AlignmentType.START}),
                    MyTableCell({text: f.isValid(r.descripcion)?r.descripcion:'', size: '25%', bold: true, alignment: AlignmentType.START}),
                    MyTableCell({text: `${r.cantidadSolicitada}`, size: '10%', bold: false, alignment: AlignmentType.CENTER}),
                    MyTableCell({text: `${r.cantidadAutorizada}`, size: '10%', bold: false, alignment: AlignmentType.CENTER}),
                    MyTableCell({text: '', size: '10%', bold: false, alignment: AlignmentType.CENTER}),
                  ]
                }))
              })
              sections[0].children.push(
                new Table({
                  rows,
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  }})
              )
            })
          },
          '${PERSONAL}': (p) => {
            const py = JSON.parse(p)
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            const rows = []
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Nombre', size: '40%', bold: true, alignment: AlignmentType.START, shading}),
                MyTableCell({text: 'País', size: '15%', bold: true, alignment: AlignmentType.START, shading}),
                MyTableCell({text: 'Cedula o pasaporte', size: '15%', bold: true, alignment: AlignmentType.START, shading}),
                MyTableCell({text: 'Institución', size: '30%', bold: true, alignment: AlignmentType.CENTER, shading}),
              ]
            }))
            py.forEach(it => {
              rows.push(new TableRow({
                children: [
                  MyTableCell({text: it.nombre, size: '40%', bold: false, alignment: AlignmentType.START}),
                  MyTableCell({text: it.pais, size: '15%', bold: false, alignment: AlignmentType.START}),
                  MyTableCell({text: it.cedula, size: '15%', bold: false, alignment: AlignmentType.START}),
                  MyTableCell({text: it.institucion, size: '30%', bold: false, alignment: AlignmentType.CENTER}),
                ]
              }))
            })
            sections[0].children.push(
              new Table({
                rows,
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }})
            )
          },
          '${ACCESO.CONOCIMIENTO}': (p) => {
            const py = JSON.parse(p)
            if(py.accesoConocimiento) {
              if(f.isValid(py.clpi) && py.clpi !== '') {
                sections[0].children.push(dxC1C2({
                  text1: 'Número de contrato/CLPI:',
                  text2: py.clpi, c1width:50
                }))
              }
              if(f.isValid(py.contratoAccesoConocimiento) && py.contratoAccesoConocimiento !== '') {
                sections[0].children.push(dxC1C2({
                  text1: 'Registro de contrato de acceso al conocimiento tradicional',
                  text2: py.contratoAccesoConocimiento, c1width:50
                }))
              }
              if(f.isValid(py.legitimosPoseedores) && py.legitimosPoseedores !== '') {
                sections[0].children.push(dxC1C2({
                  text1: 'Legítimos poseedores',
                  text2: py.legitimosPoseedores, c1width:50
                }))
              }
              if(f.isValid(py.nacionalidad) && py.nacionalidad !== '') {
                sections[0].children.push(dxC1C2({
                  text1: 'Nacionalidad',
                  text2: py.nacionalidad, c1width:50
                }))
              }
              const usuariosAutorizados = py?.usuariosAutorizados?.map(it => `${it.usuario} (${it.pais})`).join(', ')
              if(f.isValid(usuariosAutorizados) && usuariosAutorizados !== '') {
                sections[0].children.push(dxC1C2({
                  text1: 'Usuarios Autorizados',
                  text2: usuariosAutorizados, c1width:50
                }))
              }
            } else {
              sections[0].children.push(dxParagraph([dxTextRun({text: 'No.'})]))
            }
          },
          '${DOTTED.ITEMS}': (p) => {
            p?.split('{|}').forEach(it => {
              sections[0].children.push(dxBulletParagraph([
                dxTextRun({text:it})
              ]))
            })
          },
          '${PARRAFO.CONDICION}': (p) => {
            if(p.startsWith('+') && !['+[POSITIVO]','+[NEGATIVO]'].includes(p)) {
              sections[0].children.push(dxParagraph([
                dxTextRun({text:p.substring(1)})
              ]))
            }
          },
          '${BAREMO}': (p) => {
            const bar = JSON.parse(p)
            const mResultado = bar.laboratorio?.ponderacion * bar.laboratorio?.calificacion / 5 +
              bar.campo?.ponderacion * bar.campo?.calificacion / 5 +
              bar.resultados?.ponderacion * bar.resultados?.calificacion / 5 +
              bar.informacion?.ponderacion * bar.informacion?.calificacion / 5 +
              bar.experiencia?.ponderacion * bar.experiencia?.calificacion / 5 +
              bar.congruencia?.ponderacion * bar.congruencia?.calificacion / 5
            const shading = {fill: "ffffff", type: ShadingType.SOLID, color: "eeeeee",}
            const rows = []
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Evaluación Técnica', size: '40%', bold: true, alignment: AlignmentType.CENTER, shading}),
                MyTableCell({text: 'Ponderación', size: '20%', bold: true, alignment: AlignmentType.CENTER, shading}),
                MyTableCell({text: 'Calificación', size: '20%', bold: true, alignment: AlignmentType.CENTER, shading}),
                MyTableCell({text: 'Valor', size: '20%', bold: true, alignment: AlignmentType.CENTER, shading}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Congruencia de la información presentada (título, antecedentes, justificación, objetivos)', size: '40%', bold: false, alignment: AlignmentType.START}),
                MyTableCell({text: `${bar.congruencia?.ponderacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.congruencia?.calificacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.congruencia.ponderacion * bar.congruencia.calificacion / 5}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Experiencia relacionada del equipo técnico', size: '40%', bold: false, alignment: AlignmentType.START}),
                MyTableCell({text: `${bar.experiencia?.ponderacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.experiencia?.calificacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.experiencia.ponderacion * bar.experiencia.calificacion / 5}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Información detallada de los especímenes a ser investigados', size: '40%', bold: false, alignment: AlignmentType.START}),
                MyTableCell({text: `${bar.informacion?.ponderacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.informacion?.calificacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.informacion.ponderacion * bar.informacion.calificacion / 5}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Resultados esperados concuerdan con los objetivos propuestos', size: '40%', bold: false, alignment: AlignmentType.START}),
                MyTableCell({text: `${bar.resultados?.ponderacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.resultados?.calificacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.resultados.ponderacion * bar.resultados.calificacion / 5}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Metodología detallada para recolección y preservación de especímenes en campo', size: '40%', bold: false, alignment: AlignmentType.START}),
                MyTableCell({text: `${bar.campo?.ponderacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.campo?.calificacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.campo.ponderacion * bar.campo.calificacion / 5}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Metodología detallada para trabajo en laboratorio', size: '40%', bold: false, alignment: AlignmentType.START}),
                MyTableCell({text: `${bar.laboratorio?.ponderacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.laboratorio?.calificacion}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${bar.laboratorio.ponderacion * bar.laboratorio.calificacion / 5}`, size: '20%', bold: false, alignment: AlignmentType.CENTER}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'RESULTADO', colSpan:3, size: '80%', bold: true, alignment: AlignmentType.CENTER}),
                // MyTableCell({text: bar.laboratorio?.ponderacion, size: '20%', bold: true, alignment: AlignmentType.CENTER}),
                // MyTableCell({text: bar.laboratorio?.calificacion, size: '20%', bold: true, alignment: AlignmentType.CENTER}),
                MyTableCell({text: `${mResultado}`, size: '20%', bold: true, alignment: AlignmentType.CENTER}),
              ]
            }))
            sections[0].children.push(
              new Table({
                rows,
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }})
            )
          },
          '${ELABORAN.PERMISO}': (p) => {
            const personal = JSON.parse(p)
            const rows = []
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Acción', colSpan:1, size: '20%', bold: true, alignment: AlignmentType.CENTER}),
                MyTableCell({text: 'Nombre y apellido', colSpan:1, size: '30%', bold: true, alignment: AlignmentType.CENTER}),
                MyTableCell({text: 'Firma', colSpan:1, size: '30%', bold: true, alignment: AlignmentType.CENTER}),
                MyTableCell({text: 'Fecha', colSpan:1, size: '20%', bold: true, alignment: AlignmentType.CENTER}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Elaborado por:', colSpan:1, size: '20%', bold: false, alignment: AlignmentType.LEFT}),
                MyTableCell({text: personal.elabora, colSpan:1, size: '30%', bold: false, alignment: AlignmentType.LEFT}),
                MyTableCell({text: '', colSpan:1, size: '30%', bold: false, alignment: AlignmentType.LEFT}),
                MyTableCell({text: personal.fecha, colSpan:1, size: '20%', bold: false, alignment: AlignmentType.LEFT}),
              ]
            }))
            rows.push(new TableRow({
              children: [
                MyTableCell({text: 'Revisado por:', colSpan:1, size: '20%', bold: false, alignment: AlignmentType.LEFT}),
                MyTableCell({text: personal.revisa, colSpan:1, size: '30%', bold: false, alignment: AlignmentType.LEFT}),
                MyTableCell({text: '', colSpan:1, size: '30%', bold: false, alignment: AlignmentType.LEFT}),
                MyTableCell({text: personal.fecha, colSpan:1, size: '20%', bold: false, alignment: AlignmentType.LEFT}),
              ]
            }))
            sections[0].children.push(
              new Table({
                rows,
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }})
            )
          },
          '${DEPOSITO.MUESTRAS}': () => {
            // const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: `Nombre del medio de conservación y manejo ex situ`, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Acrónimo', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre del curador/administrador', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Teléfono', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Correo electrónico ', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Repositorio de documentos de constancia de depósito de muestras', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            sections[0].children.push(new Table({
              rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }}))
          },
          '${NUEVAS.ESPECIES}': () => {
            // const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: 'Grupo taxonómico', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Título del artículo científico donde se describe', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'DOI,URI (si es el caso)', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre de la revista donde se publicó', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Factor de impacto Scopus', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            sections[0].children.push(new Table({
              rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }}))
          },
          '${WF15.ANEXOS}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: 'Tipo de material', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Título', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Autor(es)', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Fecha de publicación', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'URI/DOI (Si es el caso)', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Número de Anexo', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach(it => {
              rows.push(
                new TableRow({
                  children: [
                    MyTableCell({text: it.anxMaterial, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.anxTitulo, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.anxAutores, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.anxPublicacion, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.anxUri, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.anxNumero, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  ],
                })
              )
            })

            sections[0].children.push(new Table({
              rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }}))
          },
          '${WF15.NUEVAS.ESPECIES}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: 'Grupo taxonómico', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre científico', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Título del artículo científico', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'DOI/URI', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Nombre de la revista', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Factor impacto SCOPUS', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach(it => {
              rows.push(
                new TableRow({
                  children: [
                    MyTableCell({text: it.grupoTaxonomico, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.nombre, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.articulo, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.doiUri, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.revista, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.impacto, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  ],
                })
              )
            })

            sections[0].children.push(new Table({
              rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }}))
          },
          '${WF15.DEPOSITO.MUESTRAS}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            // Medio de conservación y manejo ex situ ,Acrónimo,Curador/administrador,Teléfono,Correo electrónico,Repositorio de documentos
            rows.push(
              new TableRow({
                children: [
                  MyTableCell({text: 'Medio de conservación y manejo ex situ', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Acrónimo', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Curador/administrador', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Teléfono', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Correo electrónico', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Repositorio de documentos', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach(it => {
              rows.push(
                new TableRow({
                  children: [
                    MyTableCell({text: it.nombreMedio, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.acronimo, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.nombreCurador, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.telefono, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.eMail, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it.repositorio, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  ],
                })
              )
            })

            sections[0].children.push(new Table({
              rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }}))
          },
          '${WF15.RESULTADOS.ESPERADOS}': (p) => {
            const items = JSON.parse(p)
            const rows = []
            // Medio de conservación y manejo ex situ ,Acrónimo,Curador/administrador,Teléfono,Correo electrónico,Repositorio de documentos
            rows.push(
              new TableRow({
                children: [
                  // MyTableCell({text: 'Resultado esperado', size: 16, alignment: AlignmentType.CENTER, bold:true}),
                  MyTableCell({text: 'Resultado alcanzado', size: 32, alignment: AlignmentType.CENTER, bold:true}),
                ],
              })
            )
            items.forEach(it => {
              rows.push(
                new TableRow({
                  children: [
                    // MyTableCell({text: it.resultadoEsperado, size: 16, alignment: AlignmentType.CENTER, bold:true}),
                    MyTableCell({text: it, size: 32, alignment: AlignmentType.LEFT, bold:false}),
                  ],
                })
              )
            })

            sections[0].children.push(new Table({
              rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              }}))
          }
        }[res[0]]
        if(f.isValid(fn)) {
          fn(parrafo)
          if(salto2) {
            sections[0].children.push(dxTitle({text:'', level: 'HEADING_4', after:120}))
            sections[0].children.push(dxTitle({text:'', level: 'HEADING_4', after:120}))
          }
          if(salto3) {
            sections[0].children.push(dxTitle({text:'', level: 'HEADING_4', after:120}))
            sections[0].children.push(dxTitle({text:'', level: 'HEADING_4', after:120}))
            sections[0].children.push(dxTitle({text:'', level: 'HEADING_4', after:120}))
          }
        } else {
          console.log('*>*> ', res[0])
        }
      }
    }
  })

  return new Document({sections:sections})
}

const checkDocxTag = (it) => {
  return it?.match(/\$\{[\w\.]*\}/)
}

const mix = (taggeDoc, mapping) => {
  let doc  = taggeDoc.split('\n').join(' ')

  Object.keys(mapping).forEach(it => {
    doc = doc?.replaceAll(it, mapping[it])
  })

  return doc
}
