import React from 'react'
import {Grid} from '@mui/material'
import {
  AccountCircle,
  Badge,
  Wc,
  CoPresent,
} from '@mui/icons-material'
import {MyReadOnlyTextField} from "src/components/MyReadOnlyTextField"
import {f} from 'src/commons'


const Solicitud = (props) => {
  const s = props.solicitud
  const o = props.original
  let p = !!s?JSON.parse(s.payload):{}

  if(f.isValid(p?.Solicitud?.solicitudAprobada)) {
    p = JSON.parse(p.Solicitud.solicitudAprobada)
  }
  if(f.isValid(p?.father?.abuelo?.payload?.solicitud)) {
    p = p.father.abuelo.payload?.solicitud
  }

  p = f.isValid(p?.wf0102)?p.wf0102:p

  console.log(': p :', p)

  return (
    <Grid container spacing={1} sx={{margin:'0 0 0 24px'}}>
      <Grid item xs={6} >
        <MyReadOnlyTextField id='nombres'
                             label={'Solicitante'}
                             value={p?.Solicitante?.nombresCompletos}
                             icon={<AccountCircle sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField id='fecha'
                             label={'Fecha'}
                             value={s?.dateCreated}
                             icon={<Badge sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>}/>
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField id='identificador'
                             label={'Identificador'}
                             value={s?.numeroSolicitud + (f.isValidNotEmpty(o)?` (${o})`:'')}
                             icon={<Wc sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField id='proyecto'
                             rows={4}
                             label={'Proyecto'}
                             value={s?.nombreProyecto}
                             icon={<CoPresent sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>}
        />
      </Grid>
    </Grid>
  )
}

export default Solicitud
